<template>
  <v-dialog v-model="showModal" persistent max-width="500px">
    <v-card class="text-center pa-6">
      <v-card-title v-if="errorType === 'network'" class="headline justify-center">
          🛜 Problème de connexion
      </v-card-title>
      <v-card-title v-else class="headline justify-center">
          🚧 Maintenance en cours
      </v-card-title>
      <v-card-text class="text-body-1 mt-3">
        {{ errorMessage }}
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn  @click="checkBackend">Réessayer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      errorMessage: "",
      errorType: "" // 'network' ou 'server'
    };
  },
  methods: {
    async checkBackend() {
      try {
        // Vérification du backend (Strapi)
        await this.$strapi.find('post-categories', { timeout: 5000 });
        this.showModal = false;

      } catch (error) {
        console.error('Erreur lors de la vérification du backend:', error);

        if (error && error != undefined ) {
          // Si l'erreur contient une réponse HTTP, c'est une erreur du backend
          this.errorMessage = "Notre serveur est temporairement indisponible. Nous revenons très vite !";
          this.errorType = "server";
        } else {
          // Aucune réponse = problème de connexion (ex: pas d'Internet)
          this.errorMessage = "Vous semblez être hors ligne. Vérifiez votre connexion Internet.";
          this.errorType = "network";
        }
        
        this.showModal = true;
        localStorage.clear();
        this.$router.push('/login');

      }
    }
  },
  mounted() {
    // this.checkBackend();
    // Vérifie toutes les 5 secondes
    //setInterval(this.checkBackend, 10000); //Test
  }
};
</script>

<style scoped>
.v-card {
  border-radius: 12px;
}
.v-card-title {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>